#background-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: auto;
  padding: 10px;
}
#background-container {
  border: solid rgb(189, 189, 189) 2px;
  border-radius: 10px;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 20px 0;
  width: auto;
}
#background-carousel-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 40%;
  height: 80vh !important;
  margin-right: 10px;
}
#background-text {
  width: auto !important;
  padding: 0 0 0 15px !important;
  flex-basis: 55% !important;
  justify-self: center !important;
  align-self: center !important;
}
.text-white {
  color: white !important;
}
.background-heading {
  width: 100%;
}
.background-noto-sans {
  font-family: "Noto Sans KR", sans-serif !important;
  font-weight: 400 !important;
}
.background-paragraph:last-child {
  margin-bottom: 10px !important;
}
.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.background-image-container {
  height: auto;
}
.background-carousel {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
  margin-top: 10px;
}
.background-slider {
  display: flex;
  height: 100%;
  width: 700%;
  flex-shrink: 0;
  transition: all 0.75s;
}
.background-paragraph {
  margin: 5px 0 !important;
  font-size: 20px !important;
}
.background-slider div {
  flex-basis: 20%;
  width: 20%;
  flex-shrink: 0;
  flex: 1;
  display: flex;
  justify-content: center;
}
#background-controls {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
}
.background-button {
  margin: 0 40px !important;
}
