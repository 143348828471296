#menu-bar {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100px;
  width: 100%;
  margin: auto;
  background-color: #686a6e !important;
}

#menu-icon {
  color: white !important;
}
#resume-icon-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 60px;
  width: 100px;
}
#resume-text {
  color: white !important;
}
#resume-icon {
  color: white;
}
.menu-button {
  height: 40px;
  color: white !important;
  margin: 0 10px !important;
}
.menu-link-text {
  font-size: 20px !important;
  color: white !important;
}
Button {
  color: white;
}
.buttons-containter {
  display: flex;
  /* justify-content: space-evenly; */
  align-items: center;
  /* width: 100%; */
  /* margin: 4px; */
}
.home-logo {
  margin: 10px;
  margin-bottom: 25px;
  height: 80px;
}
ul {
  background-color: rgb(218, 218, 218);
}
.menu-link:hover {
  background-color: rgb(119, 189, 189) !important;
}
.menu-item:hover {
  background-color: rgb(119, 189, 189) !important;
}
