#portfolio-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 0;
  margin: 40px 0;
  margin-top: 100px;
}
#portfolio-carousel-container {
  margin-top: 20px;
  width: 75%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #717377 !important;
  border-radius: 3px;
}

#portfolio-carousel-container > div {
  width: 100%;
}

.portfolio-logo {
  height: 80px;
}

.text-white {
  color: white !important;
}

.paragraph {
  width: auto;
  margin: 5px 10px 10px 20px !important;
}

.card-title {
  margin: 10px 0 5px 20px !important;
}

.info-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #717377 !important;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.paragraph-container {
  height: auto;
  width: 100%;
}

.button-container {
  width: 100%;
  justify-content: center;
  margin: 20px 0 40px 0;
}

.stack-container {
  margin: 10px 20px 20px 20px;
}

.project-photo {
  width: 100%;
}

.photo-container {
  width: auto;
  height: 50vh;
  object-fit: cover;
}

.button {
  margin: 0 20px !important;
}
