@import url('https://fonts.googleapis.com/css2?family=Jura:wght@300;400;500;600&family=Noto+Sans+KR:wght@100;300;400&family=Nunito:ital,wght@0,200;0,300;0,400;1,200;1,300;1,400&family=Roboto+Mono:wght@100;200;300;400&family=Ubuntu:ital,wght@0,300;0,400;0,500;1,500&display=swap');
#contact-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 100px 0 0 0;
}
.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    margin-top: 20px;
}
.name-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.email-container {
    display: flex;
    justify-content: left;
    
}
.button-container {
    display: flex;
    justify-content: center;
}
.text-white{
    color: white !important;
}
.text-error{
    color: rgb(224, 70, 35) !important;
}
.message-sent {
    opacity: 0;
    transition: 1s opacity;
    display: flex;
    justify-content: center;
    flex-grow: 1;
    height: 30px;
    text-align: center;
}

.message-error {
    opacity: 0;
    transition: 1s opacity;
    display: flex;
    justify-content: center;
    flex-grow: 1;
    height: 30px;
    text-align: center;
}

.roboto {
    font-family: 'Roboto Mono', monospace !important;
}
.noto-sans {
    font-family: "Noto Sans KR", sans-serif !important;
    font-weight: 100 !important;
}
.jura {
    font-family: 'Jura', sans-serif !important;
}
.nunito {
    font-family: 'Nunito', sans-serif !important;
}
.ubuntu {
    font-family: 'Ubuntu', sans-serif !important;
}
