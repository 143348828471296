@import url("https://fonts.googleapis.com/css2?family=Jura:wght@300;400;500;600;700&family=Noto+Sans+KR:wght@100;300;400&family=Nunito:ital,wght@0,200;0,300;0,400;1,200;1,300;1,400&family=Roboto+Mono:wght@100;200;300;400;500;600&family=Ubuntu:ital,wght@0,300;0,400;0,500;1,500&display=swap");
:root {
  background-color: #686a6e;
  padding-bottom: 60px;
}
.roboto {
  font-family: "Roboto Mono", monospace !important;
}
.noto-sans {
  font-family: "Noto Sans KR", sans-serif !important;
}
.jura {
  font-family: "Jura", sans-serif !important;
}
.nunito {
  font-family: "Nunito", sans-serif !important;
}
.ubuntu {
  font-family: "Ubuntu", sans-serif !important;
}
