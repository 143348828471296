@import url(https://fonts.googleapis.com/css2?family=Jura:wght@300;400;500;600&family=Noto+Sans+KR:wght@100;300;400&family=Nunito:ital,wght@0,200;0,300;0,400;1,200;1,300;1,400&family=Roboto+Mono:wght@100;200;300;400&family=Ubuntu:ital,wght@0,300;0,400;0,500;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Jura:wght@300;400;500;600;700&family=Noto+Sans+KR:wght@100;300;400&family=Nunito:ital,wght@0,200;0,300;0,400;1,200;1,300;1,400&family=Roboto+Mono:wght@100;200;300;400;500;600&family=Ubuntu:ital,wght@0,300;0,400;0,500;1,500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#about-me-container {
  position: position;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px;
  margin-top: 100px;
}
.card {
  margin-bottom: 100px;
  width: auto;
  flex-grow: 1;
}
.card:last-child {
  margin-bottom: 40px;
}
.slider-about-me section {
  flex-basis: 33%;
  width: 33%;
  flex-shrink: 0;
  flex: 1 1;
  display: flex;
  justify-content: center;
}
.text-white {
  color: white !important;
}
/* .button-about-me {
    width: 70px !important;
    height: auto !important;
    background-color: rgb(107, 228, 228) !important;
    border: solid rgb(107, 172, 172) 3px !important;
    border-radius: 50% !important;
    align-items: center !important;
    text-align: center !important;
    margin: 0 20px !important;
} */
.button-about-me:hover {
  background-color: rgb(163, 103, 220) !important;
  border: solid rgb(197, 145, 245) 2px !important;
}
.arrow-forward-about-me,
.arrow-back-about-me {
  font-size: 50px !important;
  color: rgb(67, 36, 118) !important;
  padding: 0 35px !important;
}
.controls {
  width: 1000px;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto 10px auto;
}
.about-me-button {
  margin: 0 40px !important;
  background-color: rgb(107, 228, 228) !important;
  border: solid rgb(107, 172, 172) 3px !important;
  border-radius: 50% !important;
  width: 30px !important;
  height: 30px !important;
  padding: 30px !important;
}
.arrows {
  font-size: 40px !important;
}
.about-me-button:hover {
  background-color: rgb(163, 103, 220) !important;
  border: solid rgb(197, 145, 245) 2px !important;
}

#adventures-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: auto;
  padding: 10px;
}
#adventures-container {
  border: solid rgb(189, 189, 189) 2px;
  border-radius: 10px;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 20px 0;
  width: auto;
}
#adventures-carousel-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 40%;
  height: 80vh;
  padding: 0 0 0 15px !important;
}
#adventures-text {
  width: auto !important;
  flex-basis: 55% !important;
  justify-self: center !important;
  align-self: center !important;
}
.text-white {
  color: white !important;
}
.adventures-heading {
  width: 100%;
}
.adventures-noto-sans {
  font-family: "Noto Sans KR", sans-serif !important;
  font-weight: 400 !important;
}
.adventures-paragraph:last-child {
  margin-bottom: 10px !important;
}
.adventures-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.adventures-image-container {
  height: auto;
}
.adventures-carousel {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
  margin-top: 10px;
}
.adventures-slider {
  display: flex;
  height: 100%;
  width: 1100%;
  flex-shrink: 0;
  transition: all 0.75s;
}
.adventures-paragraph {
  margin: 5px 0 !important;
  font-size: 20px !important;
}
.adventures-slider div {
  flex-basis: 20%;
  width: 20%;
  flex-shrink: 0;
  flex: 1 1;
  display: flex;
  justify-content: center;
}
#adventures-controls {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
}
.adventures-button {
  margin: 0 40px !important;
}

#background-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: auto;
  padding: 10px;
}
#background-container {
  border: solid rgb(189, 189, 189) 2px;
  border-radius: 10px;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 20px 0;
  width: auto;
}
#background-carousel-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 40%;
  height: 80vh !important;
  margin-right: 10px;
}
#background-text {
  width: auto !important;
  padding: 0 0 0 15px !important;
  flex-basis: 55% !important;
  justify-self: center !important;
  align-self: center !important;
}
.text-white {
  color: white !important;
}
.background-heading {
  width: 100%;
}
.background-noto-sans {
  font-family: "Noto Sans KR", sans-serif !important;
  font-weight: 400 !important;
}
.background-paragraph:last-child {
  margin-bottom: 10px !important;
}
.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.background-image-container {
  height: auto;
}
.background-carousel {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
  margin-top: 10px;
}
.background-slider {
  display: flex;
  height: 100%;
  width: 700%;
  flex-shrink: 0;
  transition: all 0.75s;
}
.background-paragraph {
  margin: 5px 0 !important;
  font-size: 20px !important;
}
.background-slider div {
  flex-basis: 20%;
  width: 20%;
  flex-shrink: 0;
  flex: 1 1;
  display: flex;
  justify-content: center;
}
#background-controls {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
}
.background-button {
  margin: 0 40px !important;
}

#hobbies-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: auto;
  padding: 10px;
}
#hobbies-container {
  border: solid rgb(189, 189, 189) 2px;
  border-radius: 10px;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 20px 0;
  width: auto;
}
#hobbies-carousel-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 40%;
  height: 80vh !important;
  margin-right: 10px;
}
#hobbies-text {
  width: auto !important;
  padding: 0 0 0 15px !important;
  flex-basis: 55% !important;
  justify-self: center !important;
  align-self: center !important;
}
.text-white {
  color: white !important;
}
.hobbies-heading {
  width: 100%;
}
.hobbies-noto-sans {
  font-family: "Noto Sans KR", sans-serif !important;
  font-weight: 400 !important;
}
.hobbies-paragraph:last-child {
  margin-bottom: 10px !important;
}
.hobbies-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hobbies-image-container {
  height: auto;
}
.hobbies-carousel {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
  margin-top: 10px;
}
.hobbies-slider {
  display: flex;
  height: 100%;
  width: 1200%;
  flex-shrink: 0;
  transition: all 0.75s;
}
.hobbies-paragraph {
  margin: 5px 0 !important;
  font-size: 20px !important;
}
.hobbies-slider div {
  flex-basis: 20%;
  width: 20%;
  flex-shrink: 0;
  flex: 1 1;
  display: flex;
  justify-content: center;
}
#hobbies-controls {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
}
.hobbies-button {
  margin: 0 40px !important;
}

#contact-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 100px 0 0 0;
}
.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    margin-top: 20px;
}
.name-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.email-container {
    display: flex;
    justify-content: left;
    
}
.button-container {
    display: flex;
    justify-content: center;
}
.text-white{
    color: white !important;
}
.text-error{
    color: rgb(224, 70, 35) !important;
}
.message-sent {
    opacity: 0;
    transition: 1s opacity;
    display: flex;
    justify-content: center;
    flex-grow: 1;
    height: 30px;
    text-align: center;
}

.message-error {
    opacity: 0;
    transition: 1s opacity;
    display: flex;
    justify-content: center;
    flex-grow: 1;
    height: 30px;
    text-align: center;
}

.roboto {
    font-family: 'Roboto Mono', monospace !important;
}
.noto-sans {
    font-family: "Noto Sans KR", sans-serif !important;
    font-weight: 100 !important;
}
.jura {
    font-family: 'Jura', sans-serif !important;
}
.nunito {
    font-family: 'Nunito', sans-serif !important;
}
.ubuntu {
    font-family: 'Ubuntu', sans-serif !important;
}

#home-container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  margin-top: 200px;
}

#text-container-home {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  width: 50%;
  /* margin-left: 15px; */
}

#home-photo {
  width: 80%;
  border-radius: 50%;
  border: solid rgb(119, 189, 189) 2px;
}

.spinner-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  /* margin-bottom: 50px; */
  
}

.portfolio-image {
  background-color: #717377 !important;
}

#img-div {
  width: 30%;
  min-width: 390px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-bottom: 50px;
}

.info {
  /* margin: auto; */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  flex-wrap: wrap-reverse;
  flex-direction: row;
}

#logo-container { 
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.logo {
  width: auto;
  height: 30px;
  margin: 4px 20px;
}

.text-white {
  color: white !important;
}

.text-home {
  /* margin-left: 10px !important; */
  text-align: left !important;
}

.name-home {
  margin: 20px 0 20px -20px !important;
  transition-delay: 5s !important;
  line-height: 100% !important;
  /* margin: 25px 0 !important; */
  /* text-align: left; */
}

.first-line {
  font-size: 20px !important;
  align-self: flex-start !important;

  /* margin-left: -12px !important; */
}

.paragraph-home {
  font-weight: 200 !important;
  line-height: 200% !important;
  font-size: 20px !important;
}

#paragraph-container {
  /* margin-left: -30px !important; */
  text-align: left;
}

.languages {
  font-size: 20px !important;
  margin: 20px 0 !important;
  /* margin-left: -10px !important; */
}

.button-home {
  width: auto !important;
  background-color: rgb(119, 189, 189) !important;
  border: solid rgb(0, 255, 251) 2px !important;
}

.button-home:hover,
.phantom-button:hover {
  background-color: rgb(163, 103, 220) !important;
  border: solid rgb(197, 145, 245) 2px !important;
}

.close-button {
  /* border-radius: 50% !important; */
  /* width: 100% !important; */
  height: auto !important;
  width: auto !important;
  padding: 25% !important;
}

#contact-button-container {
  height: auto;
  width: auto;
  display: flex;
  align-items: center;
  margin: 60px auto 20px auto;
}

.phantom-button {
  width: 200px !important;
  height: 200px !important;
  background-color: rgb(119, 189, 189) !important;
  /* margin: 50px!important; */
  border: solid rgb(0, 255, 251) 5px !important;
  font-size: 300% !important;
  /* padding: 10% !important; */
  border-radius: 50% !important;
}

#phantom-buttons-container {
  /* width: 100% !important; */
  height: 0;
  position: fixed !important;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 50% !important;
}


#loader {
  text-align: center;
  background-color: #686a6e;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

#portfolio-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 0;
  margin: 40px 0;
  margin-top: 100px;
}
#portfolio-carousel-container {
  margin-top: 20px;
  width: 75%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #717377 !important;
  border-radius: 3px;
}

#portfolio-carousel-container > div {
  width: 100%;
}

.portfolio-logo {
  height: 80px;
}

.text-white {
  color: white !important;
}

.paragraph {
  width: auto;
  margin: 5px 10px 10px 20px !important;
}

.card-title {
  margin: 10px 0 5px 20px !important;
}

.info-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #717377 !important;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.paragraph-container {
  height: auto;
  width: 100%;
}

.button-container {
  width: 100%;
  justify-content: center;
  margin: 20px 0 40px 0;
}

.stack-container {
  margin: 10px 20px 20px 20px;
}

.project-photo {
  width: 100%;
}

.photo-container {
  width: auto;
  height: 50vh;
  object-fit: cover;
}

.button {
  margin: 0 20px !important;
}

#builds-container {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 0;
    margin-top: 100px;
}

.text-white {
    color: white !important;
}

#builds-p {
    width: 40% !important;
    margin: 20px 0 40px 0;
    font-weight: 300 !important;
    font-size: 20px;
}

.builds-card {
    width: 80%;
    height: auto;
    background-color: rgb(85, 134, 233);
    margin: 20px;
    border: solid rgb(143, 228, 228) 3px;
    border-radius: 100px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}

.api-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 20px;
}

#league-badge, #cornell-lab-logo, #ebird-logo {
    height: 150px;
    border: solid rgb(143, 228, 228) 3px;
    border-radius: 50%;
    /* margin: 0 0 0 30px; */
}

#sports-db {
    height: 150px;
    /* margin: 0 30px 0 0; */
}

#epl-title, #botd-title {
    font-weight: 700;
    font-size: 18px;
}

.arrow-forward-builds {
    color: rgb(67, 36, 118);
    font-size: 50px !important;
    width: 100px;
}

.logos-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 50%;
}

.info-container-builds {
    width: 45%;
    margin: 5px 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.roboto-text-builds {
    font-weight: 400 !important;
    font-size: 15px !important;
    padding: 10px;
}

.button-builds {
    margin: 20px !important;
    background-color: rgb(107, 228, 228) !important;
    border: solid rgb(107, 172, 172) 3px !important;
    border-radius: 50% !important;
    width: 30px !important;
    height: 30px !important;
    padding: 40px !important;
}

.arrow-forward-ios-builds {
    font-size: 80px !important;
    color: rgb(67, 36, 118) !important;
}


#menu-bar {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100px;
  width: 100%;
  margin: auto;
  background-color: #686a6e !important;
}

#menu-icon {
  color: white !important;
}
#resume-icon-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 60px;
  width: 100px;
}
#resume-text {
  color: white !important;
}
#resume-icon {
  color: white;
}
.menu-button {
  height: 40px;
  color: white !important;
  margin: 0 10px !important;
}
.menu-link-text {
  font-size: 20px !important;
  color: white !important;
}
Button {
  color: white;
}
.buttons-containter {
  display: flex;
  /* justify-content: space-evenly; */
  align-items: center;
  /* width: 100%; */
  /* margin: 4px; */
}
.home-logo {
  margin: 10px;
  margin-bottom: 25px;
  height: 80px;
}
ul {
  background-color: rgb(218, 218, 218);
}
.menu-link:hover {
  background-color: rgb(119, 189, 189) !important;
}
.menu-item:hover {
  background-color: rgb(119, 189, 189) !important;
}

#footer {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  background-color: #55575a !important;
  /* margin: 40px 0 0 0; */
}
/* #phantom {
    display: block;
    height: 60px;
    width: 100%;
} */
.footer-button {
  height: 30px;
  width: 30px;
  color: white !important;
  margin: 0 20px;
}
.footer-button:hover {
  cursor: pointer !important;
}

:root {
  background-color: #686a6e;
  padding-bottom: 60px;
}
.roboto {
  font-family: "Roboto Mono", monospace !important;
}
.noto-sans {
  font-family: "Noto Sans KR", sans-serif !important;
}
.jura {
  font-family: "Jura", sans-serif !important;
}
.nunito {
  font-family: "Nunito", sans-serif !important;
}
.ubuntu {
  font-family: "Ubuntu", sans-serif !important;
}

